<template>
  <span class="add-place-modal" v-if="canAdd">
    <b-modal
      dialog-class="modal-lg"
      :id="modalId"
      @ok.prevent="onSave"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Ajouter"
      :ok-disabled="!name"
      @shown="init()"
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-location-dot"></i> Ajout d'un lieu</b>
      </template>
      <b-row>
        <b-col>
          <b-form-group label="Nom" label-for="name">
            <b-form-input id="name" v-model="name"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <color-select
            :background="background"
            :color="color"
            @selected="getSelectedColor($event)"
          />
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<script>

import { mapActions } from 'vuex'
import ColorSelect from '@/components/Controls/ColorSelect.vue'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'

export default {
  name: 'add-place-modal',
  components: { ColorSelect, },
  mixins: [BackendMixin],
  props: {
    modalId: String,
  },
  data() {
    return {
      name: '',
      background: '',
      color: '',
    }
  },
  computed: {
    canAdd() {
      return this.hasPerm('home.add_place')
    },
  },
  watch: {
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    init() {
      this.name = ''
    },
    async onSave() {
      const url = '/api/home/places/'
      const data = {
        name: this.name,
        background_color: this.background,
        color: this.color,
      }
      let backendApi = new BackendApi('post', url)
      try {
        const resp = await backendApi.callApi(data)
        await this.addSuccess('Le lieu a été ajouté')
        this.$emit('done', resp.data)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.$bvModal.hide(this.modalId)
    },
    getSelectedColor(event) {
      this.background = event.color.background
      this.color = event.color.text
    },
  },
  mounted() {
  },
}
</script>

<style scoped>
</style>
