<template>
  <span class="add-account-button" v-if="canAdd">
    <b-modal
      dialog-class="modal-lg"
      :id="modalId"
      @ok.prevent="onSave"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Ajouter"
      :ok-disabled="!name"
      @shown="init()"
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-table"></i> Ajout d'un compte</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <b-row>
        <b-col>
          <b-form-group label="Code" label-for="name">
            <b-form-input type="text" id="name" v-model="name" required></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Libellé" label-for="label">
            <b-form-input type="text" id="label" v-model="label"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="hasShowYearChoice">
        <b-col>
          <b-form-group label="Mode d'affichage" label-for="showYear">
            <b-select id="showYear" v-model="showYear">
              <b-select-option v-for="choice in showYearChoices" :key="choice.id" :value="choice.id">
                {{ choice.name }}
              </b-select-option>
            </b-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Ordre" label-for="order">
            <b-form-input type="number" step=1 id="order" v-model="order"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="modelName === 'analytic'">
        <b-col>
          <b-form-group label="Priorité" label-for="priority">
            <b-form-input type="number" step=1 id="priority" v-model="priority"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="hasGroupChoice">
        <b-col>
          <b-form-group label="Groupe" label-for="group">
            <vue-bootstrap-typeahead
              v-model="group"
              ref="groupTypeAhead"
              :data="groups"
            >
            </vue-bootstrap-typeahead>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<script>

import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { getAccountDisplayModeChoices } from '@/types/payments'

export default {
  name: 'add-account-modal',
  mixins: [BackendMixin],
  props: {
    modalId: String,
    modelName: String,
  },
  data() {
    return {
      name: '',
      label: '',
      showYear: 0,
      showYearChoices: [],
      groups: [],
      group: null,
      order: 0,
      priority: 0,
      errorText: '',
    }
  },
  computed: {
    canAdd() {
      return this.hasPerm('payments.add_' + this.modelName + 'account')
    },
    hasShowYearChoice() {
      return this.modelName === 'analytic'
    },
    hasGroupChoice() {
      return this.modelName === 'analytic'
    },
  },
  watch: {
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    init() {
      this.name = ''
      this.label = ''
      this.showYear = 0
      this.group = null
      this.order = 0
      this.priority = 0
    },
    async getGroups() {
      if (this.hasGroupChoice) {
        const url = '/api/' + this.modelName + '-groups/'
        let backendApi = new BackendApi('get', url)
        try {
          let resp = await backendApi.callApi()
          this.groups = resp.data.map(elt => elt.name)
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
      }
    },
    async onSave() {
      this.errorText = ''
      const url = '/api/' + this.modelName + '-accounts/'
      const data = {
        name: this.name,
        label: this.label,
        order: +this.order,
      }
      if (this.hasShowYearChoice) {
        // analytiques
        data['show_year'] = this.showYear
        data['group'] = this.group
        data['priority'] = this.priority
      }
      let backendApi = new BackendApi('post', url)
      try {
        const resp = await backendApi.callApi(data)
        await this.addSuccess('Le compte a été ajouté')
        this.$emit('done', resp.data)
        this.$bvModal.hide(this.modalId)
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
    },
  },
  mounted() {
    this.showYearChoices = getAccountDisplayModeChoices()
    this.getGroups()
  },
}
</script>

<style scoped>
</style>
